import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSupport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('support', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getSupportById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`support/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveSupport(ctx, supportData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveSupport`, supportData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    listSection(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('listSupportSection', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    listStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('listSupportStatus', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveStatus(ctx, supportData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveSupportStatus`, supportData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    removeStatus(ctx, supportActivityData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`removeSupportStatus`, supportActivityData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    listContacts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('listSupportContacts')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    listClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('getClientAll',{params:queryParams})
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
        
    listProjects(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('getClientIdProjects',{params:queryParams})
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    


    saveSupportProject(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveSupportProject', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    saveSection(ctx, supportData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveSupportSection`, supportData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveSupportComment(ctx, supportCommentData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveSupportComment`, supportCommentData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    editComment(ctx, supportCommentData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`editSupportComment`, supportCommentData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    listSupportComments(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`listSupportComments/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    listSupportActivity(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`listSupportActivity/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveSupportActivity(ctx, supportActivityData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveSupportActivity`, supportActivityData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateActivityMessage(ctx, supportActivityData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateActivityMessage`, supportActivityData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    cancelSupportMeeting(ctx, supportActivityData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`cancelSupportMeeting`, supportActivityData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    removeSection(ctx, supportActivityData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`removeSupportSection`, supportActivityData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateSection(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateSupportSection`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateSectionName(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateSupportSectionName`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateStatus(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateSupportStatus`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateStatusName(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateSupportStatusName`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateSupport(ctx, supportData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`support/${supportData.id}`, supportData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateSupportIsCompleted(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`updateSupportIsCompleted`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    interactionLog(ctx, interactionLog) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`interactionLogSupport`, interactionLog)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateAssignSupport(ctx, supportData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`assignUpdate/${supportData.id}`, supportData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('supportUserList', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchSupportClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('fetchSupportClients', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    downloadReportSupport(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('downloadReportSupport', data, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchSupports(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('supportsList', { params: payload })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    updateOrderNumbers(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateSupportOrderNumbers', { params: payload })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    addTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/apps/todo/tasks', { task: taskData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateTask(ctx, { task }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`/apps/todo/tasks/${task.id}`, { task })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removeTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`/apps/todo/tasks/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
