<template>
  <!-- Header -->
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->

    <b-overlay :show="formShow" rounded="sm" no-fade>
      <b-card>
        <b-row>
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col">Add Supports</th>
              </tr>
            </thead>
          </table>
        </b-row>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form @submit.prevent="handleSubmit(saveSupports)">
            <div class="mt-2">
              <b-row>
                <b-col cols="12" class="mb-1">
                  <validation-provider name="Developer Team" #default="{ errors }" rules="required">
                    <label>Team</label>
                    <v-select v-model="supportsData.team" :options="teams" label="title" :reduce="(val) => val.id" class="small-v-select" :clearable="false" placeholder="Choose a Team"></v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="mb-1">
                  <validation-provider name="Assigned User" #default="{ errors }" rules="required">
                    <label class="mr-1 w-25">User</label>
                    <v-select v-model="supportsData.assignedUser" :taggable="true" :options="users" label="value" class="small-v-select" :value="supportsData.assignedUser" :reduce="(val) => val.assignedUser" :clearable="false" placeholder="Choose a User"> </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="mb-1">
                  <validation-provider name="First Name" #default="{ errors }" rules="required">
                    <label class="mr-1 w-25">Contact</label>
                    <v-select v-model="supportsData.firstName" label="fullName" :options="paginated" class="small-v-select" :reduce="(val) => val.fullName" :clearable="false" :filterable="false" @open="onOpen" @close="onClose" @search="(query) => (search = query)" placeholder="Select a Contact">
                      <template #list-footer>
                        <li v-show="hasNextPage" ref="load" class="loader">
                          Loading more options...
                        </li>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="mb-1">
                  <validation-provider name="Email" #default="{ errors }" rules="email">
                    <label class="mr-1 w-25">Email</label>
                    <b-form-input v-model="supportsData.email" id="d-email" placeholder="Email" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <!-- lastname -->
              <!-- <b-col cols="12" class="mb-1">
                <b-form-input v-model="supportsData.lastName" id="h-lastname" placeholder="Lastname" />
              </b-col> -->

              <!-- email -->

              <b-row>
                <!-- phone -->
                <b-col cols="12" class="mb-1">
                  <label class="mr-1 w-25">Phone</label>
                  <b-form-input v-model="supportsData.phone" id="d-phone" placeholder="Phone" />
                </b-col>
              </b-row>

              <b-row>
                <!-- location -->
                <b-col cols="12" class="mb-1">
                  <label class="mr-1 w-25">Location</label>
                  <b-form-input v-model="supportsData.location" id="d-location" placeholder="Location" />
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="mb-1">
                  <quill-editor id="quil-content" v-model="supportsData.message" :options="editorOption" />
                  <div id="quill-toolbar" class="d-flex justify-content-end">
                    <button class="ql-bold"></button>
                    <button class="ql-underline"></button>
                    <button class="ql-background" value="#fdff32"></button>
                    <button class="ql-link"></button>
                  </div>
                </b-col>
              </b-row>
            </div>

            <!-- Description -->

            <b-row>
              <!-- Form Actions -->
              <b-col cols="12" class="mt-5">
                <b-button variant="primary" class="mr-1 float-right" type="submit">
                  Add
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback, BTabs, BTab, VBTooltip, BBadge } from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email, url } from '@validations';
import { quillEditor } from 'vue-quill-editor';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import axiosIns from '@/libs/axios';
import supportsStoreModule from './supportsStoreModule';
import { ref, watch, computed, onUnmounted } from '@vue/composition-api';

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    BBadge,

    // 3rd party packages
    vSelect,
    flatPickr,
    quillEditor,
    VuePerfectScrollbar,
    AppTimeline,
    AppTimelineItem,
    Multiselect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  setup() {
    const SUPPORT_APP_STORE_MODULE_NAME = 'supports';
    // Register module
    if (!store.hasModule(SUPPORT_APP_STORE_MODULE_NAME)) store.registerModule(SUPPORT_APP_STORE_MODULE_NAME, supportsStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUPPORT_APP_STORE_MODULE_NAME)) store.unregisterModule(SUPPORT_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    const blankSupports = {
      id: null,
      assignedUser: null,
      userId: null,
      firstName: null,
      lastName: null,
      source: 'PORTAL',
      email: null,
      phone: null,
      method: null,
      message: null,
      section: null,
      team: null,
      section: 'New Supportss',
      status: 'A',
    };

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your message',
    };

    const commentOption = {
      modules: {
        toolbar: '#comment-toolbar',
      },
      placeholder: 'Add a note',
    };

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    const supportsData = JSON.parse(JSON.stringify(blankSupports));

    return {
      supportsData,
      blankSupports,
      editorOption,
      commentOption,
      formShow: false,

      required,
      email,
      url,
      methods: ['PHONE', 'EMAIL', 'SMS', 'LETTER', 'WEBSITE'],
      tempComment: null,
      visibleCommentsCount: 3,
      showCommentToolbar: false,
      teams: [
        {
          title: 'EXCEL TEAM',
          id: 'EXCEL',
        },
        {
          title: 'WEB TEAM',
          id: 'WEB',
        },
        {
          title: 'OTHER',
          id: 'OTHER',
        },
      ],
      users: [],
      comments: [],
      activities: [],

      commentLoading: true,
      activityLoading: true,
      supportsFileURL: store.state.app.supportsFileURL,
      perfectScrollbarSettings,
      scrollAreaHeight: 500,

      contacts: [],
      search: '',
      observer: null,
      limit: 10,
      userData: JSON.parse(localStorage.getItem('userData')),
      dateNtim: null,
      meetingDateNtim: null,
      meetingDuration: null,
      multiSelectUsers: [],

      selectedOption: null,
      options: ['Call', 'Mail', 'Check', 'Meeting', 'Quote', 'Invoice'],
      newActivity: false,
      description: null,
      newFile: false,

      modalFileName: null,
      modalFile: null,
      percentCompleted: 0,
    };
  },

  created() {
    this.getUsers();
  },

  computed: {
    visibleComments() {
      return this.comments.slice(0, this.visibleCommentsCount).reverse();
    },
    moreCommentsAvailable() {
      return this.comments.length > this.visibleCommentsCount;
    },

    filtered() {
      const search = this.search || '';
      return this.contacts.filter((contact) => (contact.firstName || '').toLowerCase().includes(search.toLowerCase()) || (contact.lastName || '').toLowerCase().includes(search.toLowerCase()));
    },
    paginated() {
      return this.filtered.slice(0, this.limit);
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length;
    },
  },

  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },

  methods: {
    getUsers() {
      store
        .dispatch('supports/fetchUsers', [])
        .then((res) => {
          res.data.user.forEach((element) => {
            if (element != null) {
              this.users.push({
                assignedUser: Number(element.id),
                value: String(element.name + ' ' + element.surname),
              });
            }
          });
          this.users.push({
            assignedUser: Number(0),
            value: 'All Users',
          });

          this.users = this.users.reverse();
          this.listContacts();
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'User list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
    },

    editCommentOpen(comment) {
      if (comment.userId == this.userData.id) {
        this.comments.forEach((comment) => {
          comment.isEditing = false;
        });
        comment.isEditing = true;
      }
    },

    cancelComment() {
      this.comments.forEach((comment) => {
        comment.isEditing = false;
      });
    },

    calculateScrollAreaHeight(val) {
      const commentTabContent = document.querySelector('#sidebar-supports-handler');

      if (commentTabContent) {
        this.scrollAreaHeight = commentTabContent.clientHeight - 250;
      }
    },

    clearSupportsData() {
      this.$emit('update:supports-data', JSON.parse(JSON.stringify(this.blankSupports)));

      this.$refs.refFormObserver.reset();

      this.visibleCommentsCount = 3;
    },

    closeSidebar(val) {
      this.newActivity = false;
      this.$emit('update:is-supports-handler-sidebar-active', val);
    },

    listComments() {
      this.commentLoading = true;
      store
        .dispatch('supports/listSupportsComments', { id: this.supportsData.id })
        .then((res) => {
          this.comments = res.data;
          this.commentLoading = false;
          this.calculateScrollAreaHeight();
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'Comment list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });

          this.commentLoading = false;
        });
    },

    listActivity() {
      this.activityLoading = true;
      this.activities = [];
      store
        .dispatch('supports/listSupportsActivity', { id: this.supportsData.id })
        .then((res) => {
          this.activities = res.data;
          this.activityLoading = false;
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'Activity list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          });
          this.activityLoading = false;
        });
    },

    listContacts() {
      store
        .dispatch('supports/listContacts')
        .then((res) => {
          this.contacts = res.data;
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'Contacts list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
    },

    showMoreComments() {
      this.visibleCommentsCount = this.comments.length;
      this.calculateScrollAreaHeight();
    },

    saveSupports() {
      if (this.supportsData.id != null) {
        store.dispatch('supports/updateSupports', this.supportsData).then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Supports has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          router.push({ name: 'supportss-dashboard' });
        });
      } else {
        this.supportsData.status = 'B';
        store
          .dispatch('supports/saveSupports', this.supportsData)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                text: 'Supports was added successfully! 👍',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            });

            router.push({ name: 'supportss-dashboard' });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: '❌ Please try again or report an issue to support.',
                icon: 'XIcon',
                variant: 'danger',
              },
            });
            console.log(error);
          });
      }
    },

    saveComment(supportsId) {
      store
        .dispatch('supports/saveSupportsComment', {
          supportsId: supportsId,
          comment: this.tempComment,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              text: 'Supports comment was added successfully! 👍',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
          this.tempComment = null;

          this.listComments();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
          console.log(error);
        });
    },

    editComment(commentId, comment) {
      store
        .dispatch('supports/editComment', {
          commentId: commentId,
          comment: comment,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              text: 'Supports comment was edited successfully! 👍',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
          this.tempComment = null;

          this.listComments();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: '❌ Please try again or report an issue to support.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
          console.log(error);
        });
    },

    formatTimeAgo(dateTimeString, updatedDateTimeString) {
      const now = new Date();
      const date = new Date(dateTimeString);
      const updatedDate = new Date(updatedDateTimeString);
      const diffInMilliseconds = now - date;
      const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const diffInHours = Math.floor(diffInMinutes / 60);
      const diffInDays = Math.floor(diffInHours / 24);

      if (diffInSeconds < 15) {
        return `Just now`;
      } else if (diffInSeconds >= 15 && diffInSeconds < 60) {
        const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
        return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago${edited}`;
      } else if (diffInMinutes < 60) {
        const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
        return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago${edited}`;
      } else if (diffInHours < 24) {
        const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
        return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago${edited}`;
      } else {
        const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
        return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago${edited}`;
      }
    },

    formatDateTime(dateTimeString) {
      const dateTime = new Date(dateTimeString);
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      return dateTime.toLocaleDateString('en-UK', options);
    },

    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.limit = 10;
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    sanitizeHTML(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    },

    saveActivity(supportsId) {
      if (this.selectedOption != null) {
        if (this.selectedOption != 'Meeting') {
          store
            .dispatch('supports/saveSupportsActivity', {
              supportsId: supportsId,
              title: this.selectedOption,
              titleDate: this.dateNtim,
              meetingDate: this.meetingDateNtim,
              description: this.description,
              userList: this.multiSelectUsers,
            })
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  text: 'Supports activity was added successfully! 👍',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              });
              this.tempComment = null;
              this.dateNtim = null;
              this.dateTimeString = null;
              this.newActivity = false;
              this.selectedOption = null;
              this.description = null;
              (this.dateNtim = null), (this.meetingDateNtim = null), (this.meetingDuration = null), (this.multiSelectUsers = []), this.listActivity();
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  text: '❌ Please try again or report an issue to support.',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
              console.log(error);
            });
        } else if (this.selectedOption == 'Meeting' && this.dateNtim != null && this.meetingDateNtim != null) {
          store
            .dispatch('supports/saveSupportsActivity', {
              supportsId: supportsId,
              title: this.selectedOption,
              titleDate: this.dateNtim,
              meetingDate: this.meetingDateNtim,
              description: this.description,
              userList: this.multiSelectUsers,
            })
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  text: 'Supports activity was added successfully! 👍',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              });
              this.tempComment = null;
              this.dateNtim = null;
              this.dateTimeString = null;
              this.newActivity = false;
              this.selectedOption = null;
              this.description = null;
              (this.dateNtim = null), (this.meetingDateNtim = null), (this.meetingDuration = null), (this.multiSelectUsers = []), this.listActivity();
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  text: '❌ Please try again or report an issue to support.',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
              console.log(error);
            });
        }
      }
    },

    getShortDescription(selectedOption) {
      const descriptions = {
        Call: '📞 Phone call made',
        Mail: '📧 Email sent',
        Check: '✅ Checked',
        Meeting: '📅 Meeting created',
        Quote: '🧾️ Quote provided',
        Invoice: '💲 Invoice created',
        Supports: '✔️ Supports created',
      };

      if (descriptions.hasOwnProperty(selectedOption)) {
        return descriptions[selectedOption];
      }

      return 'Description not available.';
    },

    getShortVariant(selectedOption) {
      const variants = {
        Call: 'success',
        Mail: 'info',
        Check: 'danger',
        Meeting: 'primary',
        Quote: 'secondary',
        Invoice: 'dark',
        Supports: 'dark',
      };

      if (variants.hasOwnProperty(selectedOption)) {
        return variants[selectedOption];
      }

      return 'Description not available.';
    },

    toggleDescription(activity) {
      this.showFullDescription = !this.showFullDescription;
      activity.isOpening = !activity.isOpening;
    },

    getTruncatedDescription(description) {
      return description.slice(0, 50) + '...';
    },

    deleteFile(data) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          axiosIns
            .delete(`deleteSupportsFile/${data.id}`)
            .then((res) => {
              var tempList = [];
              this.supportsData.get_files.forEach((element) => {
                if (element.id != data.id) {
                  tempList.push(element);
                }
              });

              this.supportsData.get_files = tempList;
              this.formShow = false;
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },

    saveFile(supportsId) {
      if (this.percentCompleted == 100) {
        var data = {
          status: 'B',
          supportsId: supportsId,
          fileName: this.modalFileName,
          fileLink: this.modalFile,
        };
        // Trigger submit handler
        this.modalOk(data);
      }
    },

    modalOk(data) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.formShow = true;

      axiosIns
        .post('saveSupportsFile', data)
        .then((response) => {
          this.supportsData.get_files.push({
            id: response.data.id,
            fileName: response.data.fileName,
            fileLink: response.data.fileLink,
          });

          this.modalFileName = null;
          this.modalFile = null;
          this.newFile = null;

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    fileSelected(event) {
      //  this.formShow = true;
      let formData = new FormData();
      formData.append('file', event);

      const config = {
        onUploadProgress: function(progressEvent) {
          this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }.bind(this),
      };

      axiosIns
        .post('uploadSupportsFileUser', formData, config)
        .then((response) => {
          this.modalFile = response.data;

          // this.tempImage = null;
          // this.selectedImage = null;
          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    checkFormValidity() {
      const nameValid = this.$refs.fileRules.checkValidity();
      const fileValid = this.$refs.fileRules.checkValidity();

      return fileValid && nameValid;
    },
  },
  watch: {
    meetingDuration: {
      handler: function() {
        if (this.meetingDuration > 0) {
          const changedDate = this.dateNtim.replace(/(..)\/(..)\/(....) (..):(..)/, '$3-$2-$1 $4:$5');

          const currentDate = new Date(changedDate);
          this.meetingDateNtim = new Date(currentDate.getTime() + this.meetingDuration * 60 * 1000);
        }
      },
    },
    multiSelectUsers: {
      handler: function() {},
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  + #comment-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

#comment-section {
  padding: 20px;
}

.comment-info {
  flex-grow: 1; /* Bilgi alanını genişletir, avatar/simge ile kalan alanı doldurur */
}

.comment-time {
  color: #777;
  font-size: 0.8em;
  font-weight: 500;
  margin-right: 1em;
}

#activity-section {
  // background-color: #f8f9fa;
  padding: 20px;
}

#files-section {
  background-color: #f8f9fa;
  padding: 20px;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comment + .comment {
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

.activity + .activity {
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

#comment-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.more-comments-link {
  color: #007bff;
  cursor: pointer;
}

.more-comments-link:hover {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

#sidebar-supports-handler {
  position: relative;
}

.fix-comment {
  position: fixed;
  background-color: #f8f9fa;
  bottom: 0;
  width: 100%;
  padding: 20px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.no-comment {
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  color: #868e96;
  font-weight: bold;
}

.loader {
  text-align: center;
  color: #bbbbbb;
}

.multiselect__option:hover {
  background-color: transparent !important;
}

.meeting-row {
  display: flex;
  align-items: center; /* Dikey ortalama */
}

.meeting-label {
  float: right;
  font-weight: bold; /* Kalınlaştırma */
}
</style>
