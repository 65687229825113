var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"app-ecommerce-details"},[_c('b-overlay',{attrs:{"show":_vm.formShow,"rounded":"sm","no-fade":""}},[_c('b-card',[_c('b-row',[_c('table',{staticClass:"table table-sm"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Add Supports")])])])])]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveSupports)}}},[_c('div',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Developer Team","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Team")]),_c('v-select',{staticClass:"small-v-select",attrs:{"options":_vm.teams,"label":"title","reduce":function (val) { return val.id; },"clearable":false,"placeholder":"Choose a Team"},model:{value:(_vm.supportsData.team),callback:function ($$v) {_vm.$set(_vm.supportsData, "team", $$v)},expression:"supportsData.team"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Assigned User","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mr-1 w-25"},[_vm._v("User")]),_c('v-select',{staticClass:"small-v-select",attrs:{"taggable":true,"options":_vm.users,"label":"value","value":_vm.supportsData.assignedUser,"reduce":function (val) { return val.assignedUser; },"clearable":false,"placeholder":"Choose a User"},model:{value:(_vm.supportsData.assignedUser),callback:function ($$v) {_vm.$set(_vm.supportsData, "assignedUser", $$v)},expression:"supportsData.assignedUser"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mr-1 w-25"},[_vm._v("Contact")]),_c('v-select',{staticClass:"small-v-select",attrs:{"label":"fullName","options":_vm.paginated,"reduce":function (val) { return val.fullName; },"clearable":false,"filterable":false,"placeholder":"Select a Contact"},on:{"open":_vm.onOpen,"close":_vm.onClose,"search":function (query) { return (_vm.search = query); }},scopedSlots:_vm._u([{key:"list-footer",fn:function(){return [_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasNextPage),expression:"hasNextPage"}],ref:"load",staticClass:"loader"},[_vm._v(" Loading more options... ")])]},proxy:true}],null,true),model:{value:(_vm.supportsData.firstName),callback:function ($$v) {_vm.$set(_vm.supportsData, "firstName", $$v)},expression:"supportsData.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mr-1 w-25"},[_vm._v("Email")]),_c('b-form-input',{attrs:{"id":"d-email","placeholder":"Email"},model:{value:(_vm.supportsData.email),callback:function ($$v) {_vm.$set(_vm.supportsData, "email", $$v)},expression:"supportsData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('label',{staticClass:"mr-1 w-25"},[_vm._v("Phone")]),_c('b-form-input',{attrs:{"id":"d-phone","placeholder":"Phone"},model:{value:(_vm.supportsData.phone),callback:function ($$v) {_vm.$set(_vm.supportsData, "phone", $$v)},expression:"supportsData.phone"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('label',{staticClass:"mr-1 w-25"},[_vm._v("Location")]),_c('b-form-input',{attrs:{"id":"d-location","placeholder":"Location"},model:{value:(_vm.supportsData.location),callback:function ($$v) {_vm.$set(_vm.supportsData, "location", $$v)},expression:"supportsData.location"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('quill-editor',{attrs:{"id":"quil-content","options":_vm.editorOption},model:{value:(_vm.supportsData.message),callback:function ($$v) {_vm.$set(_vm.supportsData, "message", $$v)},expression:"supportsData.message"}}),_c('div',{staticClass:"d-flex justify-content-end",attrs:{"id":"quill-toolbar"}},[_c('button',{staticClass:"ql-bold"}),_c('button',{staticClass:"ql-underline"}),_c('button',{staticClass:"ql-background",attrs:{"value":"#fdff32"}}),_c('button',{staticClass:"ql-link"})])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1 float-right",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Add ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }